import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import SubHeader from "src/components/SubHeader";
import * as React from "react";
import tambraImg from "src/images/about-us/Tambra Duren.jpg";
import justinImg from "src/images/about-us/Justin Townsend.jpg";

export default function StaffGrowAsLeadersPage() {
  return (
    <BlogLayout slug="staff-from-east-oakland-grow-as-leaders">
      <Paragraph>
        While our work has expanded to serve across Alameda County, we’ve been
        steadfastly committed to{" "}
        <strong>our home neighborhood, East Oakland</strong>. This commitment is
        brought to life every day by our dedicated team — many of whom are
        lifelong East Oaklanders. Their work and their presence demonstrates:{" "}
        <strong>
          EOCP is committed to who we say we are committed to, not only in the
          people we serve, but also in the people whose careers we invest in and
          grow.
        </strong>
      </Paragraph>
      <Paragraph>
        For these staff, their proud East Oaklander identity and deep
        familiarity with our city helps build trust with those who seek support
        at EOCP. Being from the neighborhood, their reasons for coming to work
        every day at EOCP are more than just a check, or a short commute. Having
        seen (and, been directly impacted by)&nbsp;the outcomes of an evolving
        East Oakland,{" "}
        <strong>
          they show up at work with a strong vision for change, and a belief in
          all of the people who comprise our city.&nbsp;
        </strong>
      </Paragraph>
      <Paragraph>
        We are proud to introduce these passionate, resilient colleagues, who
        each bring a personal commitment to community transformation.{" "}
        <strong>
          EOCP’s team is giving back to East Oakland, and modeling how to heal,
          grow, and thrive here.
        </strong>
      </Paragraph>
      <Divider sx={{ my: 4 }} />
      <Box
        component="img"
        src={tambraImg}
        sx={{ width: "50%", float: "right" }}
        ml={3}
        mb={3}
      />
      <SubHeader>
        Meet Tambra Duren, <em>Rapid Rehousing Manager</em>
      </SubHeader>
      <Paragraph>
        <strong>
          I’ve always been a community activist, and enthusiastic about giving
          back through this kind of work.
        </strong>{" "}
        When I was 13, I wrote down my life goals: To work in my community, head
        a youth center, and help the people I knew and who mattered most to
        me&nbsp;— those I see every day on the bus, on BART, in my neighborhood.
        I have met all of these goals, and still have the same passions today.{" "}
        <strong>
          It’s gratifying and fulfilling to invest in the same people I’ve
          always cared about.
        </strong>
      </Paragraph>

      <Paragraph>
        I was born and raised in the center of East Oakland. I’ve seen it change
        over my lifetime, and I remember when there were more family-owned
        businesses, and a richness of diversity and culture. Yes, there is new
        economic growth and vibrancy, but it’s challenging to see how it has
        impacted our clients’ experiences with homelessness. The demographics of
        the people who experience homelessness here has changed. When I was a
        young girl, homelessness wasn’t as visible or prevalent. I wasn’t used
        to seeing tents all across the city. Redevelopment has brought some new
        affordable housing, but very few options for low income tenants,
        especially for Veterans, seniors, or families. I know and believe that
        no one should live without housing. Yet{" "}
        <strong>
          in my everyday work with EOCP clients, I’m seeing how changes in
          Oakland are making it more difficult for people to live a sustainable
          life here.
        </strong>
      </Paragraph>
      <Paragraph>
        <strong>
          Knowing Oakland like the back of my hand gives me a step up in my job.{" "}
        </strong>
        I know which community resources are available and which are the best
        fit for a client’s personalized needs.&nbsp; When I’m working with
        someone who is struggling with their mental health and might be
        triggered by a certain neighborhood, I understand exactly where in
        Oakland they are talking about, and I can take a more informed approach
        to support them.
      </Paragraph>
      <Divider sx={{ my: 4 }} />
      <Box
        component="img"
        src={justinImg}
        sx={{ width: "50%", float: "left" }}
        mr={3}
        mb={3}
      />
      <SubHeader>
        Meet Justin Townsend, <em>Lead Housing Advocate, Crossroads</em>
      </SubHeader>
      <Paragraph>
        <strong>
          East Oakland is a big part of my life —&nbsp;it has molded me into the
          person I am, and helped me understand how we survive and struggle.{" "}
        </strong>
        I was born and raised here, I love it here, and it will always be my
        home. It’s changing, and I think that it’s a place that is misunderstood
        and mistreated due to the hierarchies in our society.
      </Paragraph>

      <Paragraph>
        <strong>
          As someone from East Oakland, I always try to help people who come
          through our doors to understand that EOCP is here to serve them
        </strong>
        . I tell those who I serve at Crossroads: There are people in our
        community who have made it through to the other side. The hope is alive,
        there’s a way we can do it, and there is proof.&nbsp;
      </Paragraph>

      <Paragraph>
        <strong>
          I can say that there’s proof, because my journey to this job has been
          proof.
        </strong>{" "}
        I personally have been through the same things that the people we serve
        and support have experienced. I have experienced homelessness, and I’ve
        used drugs. I’ve been through the same thing.&nbsp;
      </Paragraph>

      <Paragraph>
        Speaking from experience, I understand how hard it can be to be OK with
        receiving support. I remember feeling, “You’re not going to catch me in
        no shelter.” I thought it was degrading. But because I’ve been there, I
        know first-hand how to acknowledge and give our shelter residents the
        props they need to take the next step.
        <strong>
          {" "}
          When people talk with me, they see how I can be caring and
          understanding while understanding that I’m another person from East
          Oakland, like them.&nbsp; As a white person, this connection to our
          shared roots helps a lot to build trust across race too.
        </strong>
      </Paragraph>

      <Divider sx={{ my: 4 }} />
      <SubHeader>
        <strong>Celebrate our Homegrown Leaders!</strong>
      </SubHeader>

      <Paragraph>
        At EOCP, we believe in the fulfilling, meaningful cycle of supporting
        East Oakland’s own people to build and thrive in their careers. We’re so
        proud of our East Oaklander colleagues whose journeys at EOCP have shown
        tremendous professional growth, with opportunities to evolve, step up
        into new roles internally, and keep learning. This is our continued
        commitment to helping East Oakland neighbors remain rooted in our
        community.&nbsp;
      </Paragraph>

      <Paragraph>
        <strong>
          <em>Will you support our homegrown leaders?</em>
        </strong>
      </Paragraph>

      <Paragraph>
        When you support EOCP, you’re lifting a whole community of people
        —&nbsp;those experiencing homelessness, as well as the devoted staff who
        work persistently and compassionately to care for t
      </Paragraph>
    </BlogLayout>
  );
}
